import feathersClient from 'feathers/client';
import { services } from 'store/store';
import find from 'lodash/fp/find';
import {
  format as formatDate,
  parse as parseDate,
  addDays,
  subMonths,
} from 'date-fns';

import { grab } from 'common/apiUtils';

const DESCENDING = -1;

export const getUserGroups = (name = null) =>
  name
    ? feathersClient
        .service('userGroup')
        .find({ query: { name } })
        .then(({ data }) => data[0])
    : feathersClient
        .service('userGroup')
        .find({ query: { $limit: 100 } })
        .then(({ data }) => data);

export const createContact = (email = null) =>
  feathersClient.service('contact').create({ email });

export const createUserGroup = ({ name = null, sendMultipleEmails = true }) =>
  feathersClient.service('userGroup').create({ name, sendMultipleEmails });

export const updateUserGroup = (id, { name, sendMultipleEmails }) =>
  feathersClient.service('userGroup').update(id, {
    name,
    sendMultipleEmails,
  });

export const fetchContacts = (email = null) =>
  email
    ? feathersClient
        .service('contact')
        .find({ query: { email } })
        .then(({ data }) => data[0])
    : feathersClient
        .service('contact')
        .find({ query: { $limit: 500 } })
        .then(({ data }) => data);

export const deleteContact = ({ id }) =>
  feathersClient.service('contact').remove(id, {
    cascade: true,
  });

export const deleteUserGroup = ({ id }) =>
  feathersClient.service('userGroup').remove(id, {
    cascade: true,
  });

export const addusersToGroup = async ({ contacts, users, groups }) => {
  if (contacts && contacts.length > 0) {
    const allContactAndGroupsCombination = contacts.map(contact =>
      groups.map(group => ({ userGroupId: group.id, contactId: contact.id }))
    );
    await feathersClient
      .service('contactUserGroup')
      .create([...allContactAndGroupsCombination.flat()]);
  }
  if (users && users.length > 0) {
    const allUserAndGroupsCombination = users.map(user =>
      groups.map(group => ({ userGroupId: group.id, userId: user.id }))
    );
    await feathersClient
      .service('userUserGroup')
      .create([...allUserAndGroupsCombination.flat()]);
  }
};

export const removeUserFromGroup = async ({ contact, user, group }) => {
  if (contact) {
    await feathersClient.service('contactUserGroup').remove(null, {
      query: {
        contactId: contact.id,
        userGroupId: group.id,
      },
    });
  } else {
    await feathersClient.service('userUserGroup').remove(null, {
      query: {
        userId: user.id,
        userGroupId: group.id,
      },
    });
  }
};

export const fetchCompanyCalendar = () =>
  feathersClient
    .service('company')
    .find({ query: { name: 'Amun AG', $$include: ['companyCalendar'] } })
    .then(({ data }) => data[0].companyCalendar);

export const fetchCompany = id =>
  feathersClient
    .service('company')
    .find({ query: { id, $$include: ['instruments'] } })
    .then(({ data }) => data[0]);

export const fetchCompanyByName = name =>
  feathersClient
    .service('company')
    .find({ query: { name } })
    .then(({ data }) => data[0]);

export const fetchInstrumentBalancesFromExplorers = instrumentId =>
  feathersClient
    .service('productBalance')
    .create({}, { query: { instrumentId } });

export const fetchInstrument = id =>
  feathersClient.service('instrument').get(id);

export const fetchInstrumentAndStore = id =>
  services.instrument.get(id);

export const fetchInstruments = ({ query }) =>
  feathersClient.service('instrument').find({ query });

export const patchInstrument = (id, data, params) =>
  feathersClient.service('instrument').patch(id, data, params);

export const fetchInterestInstrumentInfoByTicker = ticker =>
  feathersClient.service('interestInstrument/info').find({ query: { ticker } });

export const overrideProjectedYieldOnInterestInstrument = (instrumentId, projectedYieldOverrides) =>
  feathersClient.service('interestInstrument/projectedYieldOverride').create({ instrumentId, projectedYieldOverrides });

export const findPcf = (instrumentId, valuationDate) =>
  feathersClient
    .service('pcf')
    .find({ query: { instrumentId, valuationDate } });

export const getPcfAggregateData = (instrumentId, valuationDate) =>
  feathersClient
    .service('pcfDataGrabber')
    .create({ instrumentId, valuationDate });

const pcfServiceKeyMap = {
  pcfPrice: 'fiatPrice',
  pcfBalance: 'balance',
  pcfAccruedMgtFee: 'accruedMgtFee',
  pcfCashPosition: 'cashPosition',
};

export const savePartialPcfData = ({
  serviceName,
  instrumentId,
  ticker,
  valuationDate,
  value,
}) =>
  feathersClient.service(serviceName).create({
    origin: 'MANUAL',
    instrumentId,
    ticker,
    valuationDate,
    [pcfServiceKeyMap[serviceName]]: value,
  });

export const findPcfDates = instrumentId => {
  const date = new Date();
  return feathersClient
    .service('pcfInternalData')
    .find({
      query: {
        instrumentId,
        valuationDate: {
          $lt: formatDate(addDays(date, 1), 'YYYY-MM-DD'),
          $gt: formatDate(subMonths(date, 3), 'YYYY-MM-DD'),
        },
        $limit: 100,
        $select: ['valuationDate'],
      },
    })
    .then(({ data }) =>
      data.map(({ valuationDate }) =>
        parseDate(valuationDate, 'yyyy-MM-dd', new Date())
      )
    );
};

export const createPcfInterimStep = (data, params = {}) =>
  feathersClient.service('pcfInterimStep').create(data, params);
export const patchPcfInterimStep = (id, data, params = {}) =>
  feathersClient.service('pcfInterimStep').patch(id, data, params);

export const findPcfInterimStep = pcfId =>
  feathersClient.service('pcfInterimStep').find({ query: { pcfId } });

export const findAllPcfInterimSteps = async pcfId => {
  const pcfInterimStepService = feathersClient.service('pcfInterimStep');
  let totalStepsCount = 0;
  let alreadyFetchedStepsCount = 0;
  const interimStepsData = [];
  do {
    const {total, limit, data} = await pcfInterimStepService.find({ query: { pcfId,
          $limit: 20, //usually it has 5-20 interim steps
          $skip: alreadyFetchedStepsCount,
          $sort: {
            createdAt: DESCENDING
          }
      }
    });
    interimStepsData.push(...data);
    totalStepsCount = total;
    alreadyFetchedStepsCount += limit

  } while (alreadyFetchedStepsCount <= totalStepsCount);

  return {
    data: interimStepsData
  };
}

export const upload = data => feathersClient.service('upload').create(data);

export const getCustodianAccount = (id, query = {}) =>
  feathersClient.service('custodianAccount').get(id, { query });

export const fetchCustodianAccounts = query =>
  feathersClient.service('custodianAccount').find({
    ...(query && { query }),
  });

export const uploadBook = data =>
  feathersClient.service('instrumentBook').create(data);

export const runTask = (task, payload = {}) =>
  feathersClient.service('tasks').create({ ...payload, manualTrigger: true }, {
    query: {
      task: task,
    },
  });

export const inviteUser = data =>
  feathersClient.service('inviteUser').create(data);

export const deleteUser = data => feathersClient.service('user').remove(data);

export const fetchUsers = () =>
  feathersClient
    .service('user')
    .find({ query: { $limit: 500 } })
    .then(grab('data'));

export const fetchUserRoles = () =>
  feathersClient
    .service('userRole')
    .find({ query: { $limit: 100 } })
    .then(grab('data'));

export const fetchCompanies = () =>
  feathersClient
    .service('company')
    .find({ query: { $$include: ['roles', 'instruments'], $limit: 100 } })
    .then(grab('data'));

export const fetchCompaniesAndStore = () =>
  services
    .company
    .find({ query: { $$include: ['roles'], $limit: 100 } })

export const updateCoinbaseCustodiedProductBalances = instrumentId =>
  feathersClient.service('coinbase').create({ instrumentId });

export const fetchCompaniesByRole = role =>
  feathersClient
    .service('companyRole')
    .find({ query: { role, $limit: 100 } })
    .then(grab('data[0].companies'));

export const fetchAmunLending = () =>
  feathersClient
    .service('amunLending')
    .find({ query: { isActive: true, $limit: 100 } });

export const pcfCompare = pcfId =>
  feathersClient.service('pcfCompare').create({
    pcfId,
  });

export const fetchRebalance = (instrumentId, valuationDate) => (
  feathersClient
    .service('rebalanceData')
    .find({ query: { instrumentId, $limit: 100, valuationDate } })
    .then(grab('data'))
)

export const fetchIndexWeights = (instrumentId, valuationDate) => (
  feathersClient
    .service('indexTargetWeights')
    .find({ query: { instrumentId, $limit: 100, valuationDate } })
    .then(grab('data'))
)

export const fetchRebalanceDates = (instrumentId, valuationDate) => (
  feathersClient
    .service('rebalanceDates')
    .get(instrumentId, { query: { year: valuationDate } })
)

export const updateRebalance = (rebalanceDataId, data) => (
  feathersClient
    .service('rebalanceData')
    .patch(rebalanceDataId, data)
)

export const approveRebalance = (instrumentId, data) => (
  feathersClient
    .service('rebalanceData')
    .update(instrumentId, data)
)

export const updateRebalanceTarget = (rebalanceTargetId, instrumentId, type, valuationDate, data) => 
  feathersClient
    .service('rebalance')
    .patch(rebalanceTargetId, { instrumentId, type, valuationDate, data });

export const generateAndUploadKID = (ticker, spreadProxy) =>
  feathersClient
    .service('productData')
    .create(
      { ticker, fetchSpread: true, KID: true, spreadProxy },
      { query: { force_generate: true } }
    );

export const generateRedirectURL = (ticker) =>
  feathersClient
    .service('createKidRedirect')
    .create({ ticker: ticker });

export const generateAndUploadFactsheets = (ticker) =>
      feathersClient
        .service('productData')
        .create(
          { ticker, factSheet: true }
        )

export const fetchKIDData = (ticker, spreadProxy) =>
  feathersClient
    .service('productData')
    .create(
      { ticker: ticker, fetchSpread: true, KID: true, spreadProxy },
      { query: { calculations: true } }
    );

export const generateAndUploadLegalDocuments = instrument =>
  feathersClient
    .service('legalDocuments')
    .create({ instrumentId: instrument.id, source: 'taskRunner' });

export const executeRebalance = (trades, instrumentId, tradingDeskId) => {
  return feathersClient
    .service('tradingDesk')
    .create({ isRebalance: true, trades, instrumentId, tradingDeskId });
};

export const executeManualTrade = ({
  tradingDeskId,
  cryptoTicker,
  side,
  quantity,
  denominator,
  reason,
  rebalanceDataId
}) => {
  return feathersClient.service('tradingDesk').create({
    rebalanceDataId,
    isManualTrade: true,
    tradingDeskId,
    trades: [
      {
        method: 'doTrade',
        from: cryptoTicker,
        to: denominator,
        side: side.toUpperCase(),
        quantity,
      },
    ],
    reason,
  });
};


export const executeSingleRebalanceTrade = ({
  tradingDeskId,
  cryptoTicker,
  side,
  quantity,
  denominator,
  reason,
  rebalanceDataId,
  instrumentId
}) => {
  return feathersClient.service('tradingDesk').create({
    rebalanceDataId,
    isRebalance: true,
    instrumentId,
    tradingDeskId,
    trades: [
      {
        method: 'doTrade',
        from: cryptoTicker,
        to: denominator,
        side: side.toUpperCase(),
        quantity,
      },
    ],
    reason,
  });
};

export const initiateLendingDeskAction = ({
  instrumentId,
  lendingDeskId,
  cryptoTicker,
  action,
  quantity,
  reason,
}) => {
  return feathersClient.service('lendingDesk/borrow').create({
    instrumentId,
    ticker: cryptoTicker,
    lendingDeskId,
    action,
    quantity,
    reason,
  });
};

export const setBorrowReconciliation = data => {
  return feathersClient.service('borrowReconciliation').create({
    ...data,
  });
};
export const addAmunLending = (id, payload) => {
  return feathersClient.service('amunLending').patch(id, payload);
};

export const createAmunLending = payload => {
  return feathersClient.service('amunLending').create(payload);
};

export const createInterestInstrumentLoan = (payload) => {
  return feathersClient.service('interestInstrumentLoan').create(payload);
};

export const patchInterestInstrumentLoan = (id, payload) => {
  return feathersClient.service('interestInstrumentLoan').patch(id, payload);
};

export const patchInterestInstrumentLoanDailyCalc = (id, payload) => {
  return feathersClient.service('interestInstrumentLoanDailyCalc').patch(id, payload);
};

export const patchAmunLending = (loanId, payload) => {
  return feathersClient.service('amunLending').patch(loanId, payload);
};

export const fetchCurrentPcf = async ({ instrumentId }) => {
  const allCurrentPcfs = await feathersClient.service('pcfCurrent').find();
  const selectByInstrumentId = find(o => o.instrumentId === instrumentId);
  return selectByInstrumentId(allCurrentPcfs);
};

export const createAuditLogEntry = async ({ type, description }) => {
  return feathersClient.service('auditLog').create({ type, description });
};

export const createLendingRate = (
  { effectiveDate, lendingRate, cryptoId, lendingDeskId },
  params = {}
) =>
  feathersClient.service('lendingRate').create(
    {
      effectiveDate,
      lendingRate,
      cryptoId,
      lendingDeskId,
    },
    params
  );

export const fetchAllCollaterals = lendingDeskId =>
  feathersClient
    .service('collateral')
    .find()
    .then(({ data }) => data);

export const saveCollateralAmount = ({ amount, collateralId }, params = {}) =>
  feathersClient.service('collateral').patch(
    collateralId,
    {
      amount,
    },
    params
  );

export const startInverseRebalanceAndPcf = (
  instrumentId,
  withRebalance,
  valuationDate
) =>
  feathersClient.service('tasks').create(
    {
      instrumentId,
      withRebalance,
      valuationDate,
    },
    {
      query: {
        task: 'inversePcfAndRebalance',
      },
    }
  );

export const updateBalance = valuationDate =>
  feathersClient.service('tasks').create(
    { valuationDate },
    {
      query: {
        task: 'addBorrowToBalance',
      },
    }
  );
export const updateCashPosition = (instrumentId, valuationDate) =>
  feathersClient.service('tasks').create(
    { instrumentId, valuationDate },
    {
      query: {
        task: 'bankfrick',
      },
    }
  );
export const updatePrice = () =>
  feathersClient.service('tasks').create(
    {},
    {
      query: {
        task: 'sendTrackerPricing',
      },
    }
  );

export const updatePcfInternalData = async (instrumentId, valuationDate) => {
  if (!process.env.PCF_CREATION_DISABLED) {
    await runTask('currencyConversion');
  }
  const result = await feathersClient.service('pcfInternalData/fetch').create({
    instrumentId,
    valuationDate,
  });
  await feathersClient.service('pcfInternalData').create({
    origin: 'MANUAL',
    ...result,
  });
};

export const fakeBook = async (
  { instrumentId, totalSecuritiesOutstanding },
  params = {}
) => {
  const foundBook = await feathersClient
    .service('instrumentBook')
    .find({ query: { instrumentId } });

  await feathersClient.service('instrumentBook').patch(
    foundBook.data[0].id,
    {
      entries: [
        {
          date: '2019-01-04T07:45:16.641Z',
          entryNumber: 1,
          counterParty: 'Jane Street',
          numberOfSecurities: totalSecuritiesOutstanding,
          totalSecuritiesOutstanding: totalSecuritiesOutstanding,
        },
      ],
    },
    params
  );
};

export const addManualInverseRebalance = async (values, selectedCrypto) => {
  const {
    instrumentId,
    lendingDeskId,
    tradingDeskId,
    price,
    quantity,
    action,
    valuationDate,
  } = values;
  const cryptoId = selectedCrypto.id;
  const cryptoTicker = selectedCrypto.ticker;

  const pcfCommonData = {
    instrumentId,
    valuationDate,
    origin: 'MANUAL',
    ticker: cryptoTicker,
  };
  const pcfData = await feathersClient.service('pcfDataGrabber').create({
    instrumentId,
    valuationDate,
  });

  const inverseRebalance = await feathersClient
    .service('inverseRebalance')
    .create({
      valuationDate,
      instrumentId,
      type: 'UNSETTLED',
    });
  await feathersClient.service('lendingDesk/borrow').create({
    action,
    instrumentId,
    cryptoId,
    quantity,
    lendingDeskId,
    reason: 'Manual Inverse Rebalance',
    rebalanceId: inverseRebalance.id,
  });

  await feathersClient.service('trade').create({
    tradingDeskTradeId: null,
    instrumentId,
    action: action === 'borrow' ? 'SELL' : 'BUY',
    from: cryptoTicker,
    to: 'USD',
    quantity,
    quote: price,
    fee: 0,
    totalValue: quantity * price,
    status: 'FILLED',
    tradingDeskId,
    type: 'REBALANCE',
    inverseRebalanceId: inverseRebalance.id,
  });
  await feathersClient.service('pcfPrice').create({
    fiatPrice: price,
    type: 'OTC',
    ...pcfCommonData,
  });
  if (pcfData.success) {
    const {
      id,
      createdAt,
      updatedAt,
      ...pcfInternalData
    } = pcfData.pcfInternalData;
    await feathersClient.service('pcfInternalData').create({
      ...pcfInternalData,
      isRebalance: false,
    });
    const pricesOtc = {}
    pricesOtc[cryptoTicker] = price
    const calculatedPcf = await feathersClient.service('pcfCalculator').create({
      ...pcfData,
      accruedMgtFees: pcfData.accruedMgtFees.present,
      balances: pcfData.balances.present,
      prices: pcfData.prices.present,
      pricesOtc: pricesOtc,
      cashPositions: pcfData.cashPositions.present,
    });
    const {
      data: [pcf],
    } = await feathersClient.service('pcf').find(
      {
        query: {
          instrumentId,
          valuationDate,
          $limit: 1,
          $sort: { createdAt: -1 },
        },
      },
      { query: { skipAssociations: true } }
    );
    await feathersClient.service('pcfInterimStep').create({
      pcfId: pcf.id,
      type: 'CALCULATED_PCF',
      interimStep: calculatedPcf,
      custodianAccountId: null,
    });
  }
};
export const fakeBorrow = async (
  { instrumentId, quantity, lendingRate = 5 },
  params = {}
) => {
  const foundBorrows = await feathersClient
    .service('borrow')
    .find({ query: { instrumentId } });

  for (const borrow of foundBorrows.data) {
    await feathersClient.service('borrow').remove(borrow.id);
  }
  const foundCryptos = await feathersClient
    .service('instrument')
    .get(instrumentId);

  const cryptoId = foundCryptos.crypto[0].id;
  const foundLendingDesk = await feathersClient
    .service('company')
    .find({ query: { name: 'Genesis' } });

  const foundLendingRate = await feathersClient.service('lendingRate').create(
    {
      effectiveDate: '2018-01-01',
      lendingRate,
      cryptoId,
      lendingDeskId: foundLendingDesk.data[0].id,
    },
    params
  );
  await feathersClient.service('borrow').create({
    quantity,
    changeInQuantity: quantity,
    cryptoId,
    lendingRateId: foundLendingRate[0].id,
    instrumentId,
  });
};
// CREATE ETP:
export const createEtp = payload =>
  feathersClient.service('instrument').create(payload);

export const createInstrumentsExchangesEntry = payload =>
  feathersClient.service('instrumentExchange').create(payload);

export const fetchCryptos = () =>
  feathersClient.service('crypto').find({
    query: {
      $limit: 1000,
      skipAssociations: true
    },
  });

export const fetchMetals = () =>
  feathersClient.service('metal').find({
    query: {
      $limit: 1000,
      skipAssociations: true
    },
  });

export const fetchAndStoreInstrumentWithExchanges = (id) =>
  services.instrument.find({
    query: {
      associationsToInclude: ['exchanges'],
      id,
    }
  });

export const fetchAndStoreExchanges = () =>
  services.exchange.find({
    query: { skipAssociations: true }
  });

export const fetchExchanges = () =>
  feathersClient.service('exchange').find({
    query: { skipAssociations: true }
  });

export const createExchange = (payload) =>
  feathersClient.service('exchange').create(payload);

export const fetchInstrumentsCalendars = () =>
  feathersClient
    .service('instrumentCalendar')
    .find({
      query: {
        $limit: 100,
        year: {
          $gte: new Date().getFullYear()
        },
      },
    })
    .then(({ data }) => data);

export const fetchInstrumentsCalendarsAndStore = () =>
  services
    .instrumentCalendar
    .find({
      query: {
        $limit: 100,
        year: {
          $gte: new Date().getFullYear()
        },
      },
    })
    .then(({ data }) => data);

export const fetchInstrumentWallets = ({instrumentId, companyId}) =>
  feathersClient
    .service('wallet')
    .find({
      query: {
        $limit: 100,
        $$include: ['walletAddressHistory'],
        companyId,
        instrumentId,
      }
    })
    .then((res) => res.data.filter(wallet => wallet.addressHistory[0].isActive))

    export const generateAndSendBloombergFile = valuationDate => {
      const body = valuationDate ? { valuationDate } : {};
      return feathersClient
        .service('createBloombergXlsx')
        .create(body);
    }